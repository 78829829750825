// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header {
  top: 0;
  padding: 0;
  left: 0;
  text-align: center;
  height: 65px;
  display: flex;
  border-bottom: 1px solid #ccc;
}


.avatar {
  margin-left: 7px;
  padding: 0;
  width: 48px;
  height: 48px;
  border-radius: 65%;
}

.titleBar {
  padding-left: 17px;
  padding-top: 4px;
  display: inline;
  justify-content: left;
  align-items: left;
  font-size: x-large;
}

.admin-button {
  color: white;
  border: none;
  margin: 10px 10px 10px 10px;
  font-size: 26px;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  width: 32px;
  border-radius: 20px;
  &:hover {
    color: yellow;
  }
}

.admin-button i {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":";AACA;EACE,MAAM;EACN,UAAU;EACV,OAAO;EACP,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,6BAA6B;AAC/B;;;AAGA;EACE,gBAAgB;EAChB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,eAAe;EACf,eAAe;EACf,aAAa;EACb,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB;IACE,aAAa;EACf;AACF;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["\n.header {\n  top: 0;\n  padding: 0;\n  left: 0;\n  text-align: center;\n  height: 65px;\n  display: flex;\n  border-bottom: 1px solid #ccc;\n}\n\n\n.avatar {\n  margin-left: 7px;\n  padding: 0;\n  width: 48px;\n  height: 48px;\n  border-radius: 65%;\n}\n\n.titleBar {\n  padding-left: 17px;\n  padding-top: 4px;\n  display: inline;\n  justify-content: left;\n  align-items: left;\n  font-size: x-large;\n}\n\n.admin-button {\n  color: white;\n  border: none;\n  margin: 10px 10px 10px 10px;\n  font-size: 26px;\n  cursor: pointer;\n  display: flex;\n  margin-left: auto;\n  width: 32px;\n  border-radius: 20px;\n  &:hover {\n    color: yellow;\n  }\n}\n\n.admin-button i {\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
