// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChatButton.css */
.chat-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 50;
    display: flex;
    height: 4rem;
    width: 4rem;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.chat-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatButton.css"],"names":[],"mappings":"AAAA,mBAAmB;AAEjB;IAAA,eAAmI;IAAnI,YAAmI;IAAnI,WAAmI;IAAnI,WAAmI;IAAnI,aAAmI;IAAnI,YAAmI;IAAnI,WAAmI;IAAnI,mBAAmI;IAAnI,uBAAmI;IAAnI,qBAAmI;IAAnI,kBAAmI;IAAnI,wDAAmI;IAAnI,oBAAmI;IAAnI;AAAmI;AAAnI;IAAA,kBAAmI;IAAnI;AAAmI","sourcesContent":["/* ChatButton.css */\n.chat-button {\n  @apply fixed bottom-4 right-4 bg-blue-500 text-white rounded-full w-16 h-16 flex items-center justify-center z-50 hover:bg-blue-700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
