// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-dialog {
  transition: transform 0.3s;
}

.chat-dialog.w-0 {
  transform: translateX(100%);
}

.chat-dialog.w-80 {
  transform: translateX(0);
}

.chat-header {
  background-color: #1d4ed8;
}

.chat-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px); /* Adjust according to your header height */
}

.messages {
  overflow-y: auto;
}

.message {
  padding: 8px;
  margin-bottom: 8px;
  background-color: #374151;
  border-radius: 4px;
  text-align: left;
}

.chat-dialog form {
  display: flex;
  margin-top: 16px;
}

.chat-dialog input {
  flex: 1 1;
  padding: 8px;
  background-color: #374151;
  border: 1px solid #4b5563;
  border-radius: 4px 0 0 4px;
  color: #fff;
}

.chat-dialog button {
  padding: 8px;
  background-color: #1d4ed8;
  border: 1px solid #1d4ed8;
  border-radius: 0 4px 4px 0;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatDialog.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB,EAAE,2CAA2C;AACxE;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,0BAA0B;EAC1B,WAAW;AACb","sourcesContent":[".chat-dialog {\n  transition: transform 0.3s;\n}\n\n.chat-dialog.w-0 {\n  transform: translateX(100%);\n}\n\n.chat-dialog.w-80 {\n  transform: translateX(0);\n}\n\n.chat-header {\n  background-color: #1d4ed8;\n}\n\n.chat-content {\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 64px); /* Adjust according to your header height */\n}\n\n.messages {\n  overflow-y: auto;\n}\n\n.message {\n  padding: 8px;\n  margin-bottom: 8px;\n  background-color: #374151;\n  border-radius: 4px;\n  text-align: left;\n}\n\n.chat-dialog form {\n  display: flex;\n  margin-top: 16px;\n}\n\n.chat-dialog input {\n  flex: 1;\n  padding: 8px;\n  background-color: #374151;\n  border: 1px solid #4b5563;\n  border-radius: 4px 0 0 4px;\n  color: #fff;\n}\n\n.chat-dialog button {\n  padding: 8px;\n  background-color: #1d4ed8;\n  border: 1px solid #1d4ed8;\n  border-radius: 0 4px 4px 0;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
