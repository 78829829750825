// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-control {
  width: 100%;
  min-height: 600px;
}

.tab-header {
  display: flex;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
}

.tab-button.active {
  border-bottom: 4px inset #007bff;
}

.tab-panel-content {
  padding: 20px;
  min-height: 600px;
}

.search-result {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.search-result h3 {
  margin-bottom: 0;
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/components/TabControl.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".tab-control {\n  width: 100%;\n  min-height: 600px;\n}\n\n.tab-header {\n  display: flex;\n}\n\n.tab-button {\n  padding: 10px 20px;\n  cursor: pointer;\n  outline: none;\n}\n\n.tab-button.active {\n  border-bottom: 4px inset #007bff;\n}\n\n.tab-panel-content {\n  padding: 20px;\n  min-height: 600px;\n}\n\n.search-result {\n  text-align: left;\n  padding: 10px;\n  border-bottom: 1px solid #ccc;\n}\n\n.search-result h3 {\n  margin-bottom: 0;\n  font-weight: bolder;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
