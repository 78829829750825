// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
}

table th {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/DataGrid.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":["table {\n    border-collapse: collapse;\n    width: 100%;\n    margin-bottom: 1rem;\n    border: 1px solid #ccc;\n}\n\ntable th {\n    border: 1px solid #ccc;\n    padding: 8px;\n    text-align: center;\n}\n\ntable td {\n    border: 1px solid #ccc;\n    padding: 8px;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
